import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  navigate as reach_navigate,
  RouteComponentProps,
  useLocation
} from "@reach/router";
import useFormValidation from "@/hooks/useFormValidation";

import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  checkConfirmNewPasswordStatus,
  checkNewPasswordConfirmedStatus,
} from "@/actions/account.actions";

import AuthLayout, { defaultInputProps } from "@/components/AuthLayout";
import { Form, Input, message, Spin } from "antd";
import { Rule } from "antd/es/form";

const FIELDS_RULES: Record<string, Rule[]> = {
  password: [
    {
      required: true,
      message: "Пожалуйста, введите пароль"
    },
    {
      pattern: /(?=\S*?[A-Z])/,
      message: "Должен содержать хотя бы одну заглавную букву",
    },
    {
      pattern: /(?=\S*?[a-z])/,
      message: "Должен содержать хотя бы одну строчную букву",
    },
    {
      pattern: /(?=\S*?[0-9])/,
      message: "Должен содержать хотя бы одну цифру",
    },
    {
      pattern: /^[^\s]+$/,
      message: "Пароль не должен содержать пробелов",
    },
    {
      pattern: /^[a-zA-Z0-9\s]*$/,
      message: "Должен содержать только латинские буквы и цифры",
    },
    {
      pattern: /.{8,}/,
      message: "Должен быть длиной как минимум 8 символов",
    }
  ],
  confirmPassword: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, повторите пароль"));
        }

        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }

        return Promise.reject("Пароли не совпадают");
      },
    }),
  ]
}

interface IFormValues {
  password: string;
  confirm: string;
}

interface IResetPasswordFormProps extends RouteComponentProps { }

const ResetPasswordForm: FC<IResetPasswordFormProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [form] = Form.useForm<IFormValues>();
  const values = Form.useWatch([], form);
  const { isDisabled } = useFormValidation(form);

  const [resetTokenLink, setResetTokenLink] = useState<string>("");

  const {
    confirmNewPasswordStatus,
    newPasswordConfirmedStatus
  } = useSelector((state: AppStateType) => state.account);

  useEffect(() => {
    const token: string = location.pathname.split("/reset_password/")?.[1];

    !!token && setResetTokenLink(token);
  }, [location.pathname]);

  useEffect(() => {
    !!resetTokenLink && dispatch(checkConfirmNewPasswordStatus(resetTokenLink));
  }, [resetTokenLink]);

  useEffect(() => {
    if (newPasswordConfirmedStatus === 200) {
      message.success("Пароль успешно изменен");
    }
  }, [confirmNewPasswordStatus, newPasswordConfirmedStatus]);


  const NewPasswordForm = useCallback((): JSX.Element => {
    const isShow: boolean = confirmNewPasswordStatus === 200 && newPasswordConfirmedStatus != 200;

    const saveNewPassword = (): void => {
      dispatch(checkNewPasswordConfirmedStatus(resetTokenLink, values.password));
    }

    return (
      <AuthLayout
        isVisible={isShow}
        title="Восстановление пароля"
        message="Придумайте новый пароль"
        buttonText="Подтвердить"
        onButtonClick={saveNewPassword}
        buttonDisabled={isDisabled}
      >
        <Form
          form={form}
          name="normal_login"
          className="flex flex-col gap-3"
        >
          <Form.Item
            name="password"
            rules={FIELDS_RULES.password}
            className="m-0 w-full"
          >
            <Input.Password {...defaultInputProps} placeholder="Пароль" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={FIELDS_RULES.confirmPassword}
            className="m-0 w-full"
          >
            <Input.Password {...defaultInputProps} placeholder="Повторите пароль" />
          </Form.Item>
        </Form>
      </AuthLayout>
    );
  }, [
    confirmNewPasswordStatus,
    newPasswordConfirmedStatus,
    isDisabled
  ]);

  const CompleteForm = useCallback((): JSX.Element => {
    const isShow: boolean = newPasswordConfirmedStatus === 200;

    const openLoginForm = (): void => {
      reach_navigate("/login");
    };

    return (
      <AuthLayout
        isVisible={isShow}
        title="Восстановление пароля"
        message="Пароль успешно изменен, теперь вы можете войти в свой аккаунт используя новый пароль."
        buttonText="Войти"
        onButtonClick={openLoginForm}
      />
    );
  }, [
    newPasswordConfirmedStatus,
    values
  ]);

  const InvalidTokenForm = useCallback((): JSX.Element => {
    enum ErrorStatuses {
      EXPIRED = 400,
      NOT_FOUND = 404,
      ERROR = 500,
    };

    const ErrorMessages: Record<ErrorStatuses, string> = {
      [ErrorStatuses.EXPIRED]: "Ваша ссылка устарела",
      [ErrorStatuses.NOT_FOUND]: "Такой ссылки не существует",
      [ErrorStatuses.ERROR]: "Непредвиденная ошибка",
    };

    const hasError: boolean = confirmNewPasswordStatus in ErrorMessages;
    const isComplete: boolean = newPasswordConfirmedStatus === 200;
    const isShow: boolean = hasError && !isComplete;

    const errorMessage: string = useMemo(() => {
      return ErrorMessages[confirmNewPasswordStatus] || ErrorMessages[ErrorStatuses.ERROR];
    }, [confirmNewPasswordStatus]);

    const openRestoreForm = (): void => {
      reach_navigate("/restore");
    };

    return (
      <AuthLayout
        isVisible={isShow}
        title="Восстановление пароля"
        message={`${errorMessage}. Запросите новую ссылку на восстановление пароля.`}
        buttonText="Восстановить пароль"
        onButtonClick={openRestoreForm}
      />
    );
  }, [
    confirmNewPasswordStatus,
    newPasswordConfirmedStatus
  ]);

  return (
    <>
      <NewPasswordForm />
      <CompleteForm />
      <InvalidTokenForm />
    </>
  );

}

export default ResetPasswordForm;
